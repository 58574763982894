<template>
    <div class="sidebar">
        <div class="text-center">
            <img src="../assets/mdm_logo_placeholder.png" alt="Logo" class="logo" @click="goToHome" />
        </div>
        <ul style="margin: 0%; height: 90dvh;">
            <div class="mainmenu-container">
                <li v-for="item in imageItemsTop" :key="item.id" @click="goToPage(item.route)">
                    <div class="image-container">
                        <img :src="item.imageUrlTop" :alt="item.altText" class="icon-image" />
                        <span class="image-label">{{ item.altText }}</span>
                    </div>
                </li>
                <li v-for="item in imageItemsManageDevice" :key="item.id" @click="goToPage(item.route)">
                    <div class="image-container">
                        <img :src="item.imageUrlManageDevice" :alt="item.altText" class="icon-image mandev_icon" />
                        <span class="image-label">{{ item.altText }}</span>
                    </div>
                </li>
                <li v-for="item in imageItemsMid" :key="item.id" @click="goToPage(item.route)">
                    <div class="image-container">
                        <img :src="item.imageUrlMid" :alt="item.altText" class="icon-image" />
                        <span class="image-label">{{ item.altText }}</span>
                    </div>
                </li>
            </div>
            <div class="misc-container">
                <li v-for="item in imageItemsLower" :key="item.id" class="lower_icons_list">
                    <div class="image-container" @click="goToPage(item.route)">
                        <img :src="item.imageUrlLower" :alt="item.altText" class="icon-image" />
                        <span class="image-label">{{ item.altText }}</span>
                    </div>
                </li>
            </div>
        </ul>
    </div>
</template>

<script>

export default{
    data() {
        return{
            imageItemsTop: [
                //{id: 1, imageUrlTop: require('../assets/register_device_icon.png'), altText: 'Register Device', route: '/register-devices'},
                //{id: 2, imageUrlTop: require('../assets/manage_devices_icon.png'), altText: 'Manage Devices', route: '/device-list'},
                //{id: 3, imageUrlTop: require('../assets/manage_accounts.png'), altText: 'Manage Accounts', route: '/manage-accounts'},
                //{id: 4, imageUrlTop: require('../assets/faq_icon.png'), altText: 'FAQ', route: '/faq'},
            ],
            imageItemsManageDevice: [
                //{id: 2, imageUrlManageDevice: require('../assets/manage_devices_icon.png'), altText: 'Manage Devices', route: '/device-list'},
            ],
            imageItemsMid: [
                //{id: 1, imageUrlMid: require('../assets/manage_accounts.png'), altText: 'Manage Accounts', route: '/manage-user-admin'},
                //{id: 2, imageUrlMid: require('../assets/template_icon.png'), altText: 'Manage Templates', route: '/manage-templates'},
                //{id: 3, imageUrlMid: require('../assets/faq_icon.png'), altText: 'FAQ', route: '/faq'},
                //{id: 4, imageUrlMid: require('../assets/report.png'), altText: 'Reports', route: '/reporting'},
            ],
            imageItemsLower: [
                {id: 1, imageUrlLower: require('../assets/home_icon.png'), altText: 'Dashboard', route: '/dashboard'},
                {id: 2, imageUrlLower: require('../assets/country_flags/en_US.svg'), altText: this.langmode, route: 'langchange'},
                {id: 3, imageUrlLower: require('../assets/profile_icon.png'), altText: 'Log Out', route: '/'},
            ],
            langmode : "ENG",
            data: [],
            data_collection: [],
            isInLocalStorage: false
        }
    },
    methods: {
        goToPage(route){
            if (route === 'langchange'){
                this.langswap()
            }
            else if (route === '/') {
                localStorage.clear();
                console.log('Clearing storage');
                this.$router.replace(route); 
            }   
            else{
                this.$router.replace(route);
            }
            
        },
        goToHome(){
            this.$router.replace('/dashboard')
        },
        iconClicked(item){
            //Change the image icon and then execute the route movement
            if(item.id == 1 && item.imageUrlTop){
                item.imageUrlTop = require('../assets/register_device_navbar.png');
                this.goToPage(item);
            }
        },
        langchange(val){
            this.langmode = val
            //this.imageItemsLower[1].altText = this.langmode
            //this.imageItemsTop[0].altText = this.data[this.langmode]['top'][0];
            //this.imageItemsManageDevice[0].altText = this.data[this.langmode]['manage'][0];
            //this.imageItemsMid[0].altText = this.data[this.langmode]['mid'][0];
            //this.imageItemsMid[1].altText = this.data[this.langmode]['mid'][1];
            //this.imageItemsLower[0].altText = this.data[this.langmode]['lower'][0];
            //this.imageItemsLower[2].altText = this.data[this.langmode]['lower'][1];
            //console.log((this.data[this.langmode]['topUrl']))
            //this.imageItemsLower[1].imageUrlLower = this.data[this.langmode]['langUrl'];
            this.$emit('button-click',this.langmode);
        },
        langswap(){
            var langmode
            if (this.langmode === "ENG"){
                langmode = "BM"
            }
            else{
                langmode = "ENG"
            }
            localStorage.locale = langmode
            this.langchange(langmode)
        },
        async onlyLoadAccepted() {
            //Perform API Call
            if (localStorage.getItem('acceptedArrays') != null) {
                //Put array data here
                            this.data_collection = localStorage.getItem('acceptedArrays');
                console.log("It found something here");
                
                
            } 
            else {
                const axios = require('axios');
                let data = JSON.stringify({
                    token: localStorage.token
                });
                let config = {
                    method: 'post',
                    maxBodyLength: Infinity,
                    url: process.env.VUE_APP_BACKEND_URL+'/v1/dashboard/sidebar-view',
                    headers: { 
                        'Content-Type': 'application/json'
                    },
                    data : data
                };

                //API request
                axios.request(config)
                .then((response) => {
                    console.log(JSON.stringify(response.data));  
                    if (response.status === 200){
                        console.log("success");
                        this.data_collection = response.data.data;

                        //Debug the collected data
                        console.log("Collected data: ");
                        console.log(this.data_collection);
                        
                        localStorage.setItem('acceptedArrays', this.data_collection);
                        var newItemsImageTop = [];
                        var newimageItemsManageDevice = [];
                        var newimageItemsMid = [];
                        if ( this.data_collection ){
                            //Parse the diff arrays here
                            console.log(this.data_collection);
                            //Add accordingly
                            if ( this.data_collection.includes('Register Device') ) {
                                newItemsImageTop.push({id: 1, imageUrlTop: require('../assets/register_device_icon.png'), altText: 'Register Device', route: '/register-devices'});
                            }
                            if ( this.data_collection.includes('Manage Devices')) {
                                newimageItemsManageDevice.push({id: 2, imageUrlManageDevice: require('../assets/manage_devices_icon.png'), altText: 'Manage Devices', route: '/device-list'});
                            }
                            if ( this.data_collection.includes('Manage Accounts')) {
                                newimageItemsMid.push({id: 1, imageUrlMid: require('../assets/manage_accounts.png'), altText: 'Manage Accounts / PoS', route: '/manage-user-admin'});
                            }
                            if ( this.data_collection.includes('Manage Templates') && this.data_collection.includes('credit_system')) {
                                newimageItemsMid.push({id: 2, imageUrlMid: require('../assets/settings.png'), altText: 'Settings', route: '/settings'});
                            } else if (this.data_collection.includes('Manage Templates') && !this.data_collection.includes('credit_system')) {
                                newimageItemsMid.push({id: 2, imageUrlMid: require('../assets/template_icon.png'), altText: 'Manage Notification Templates', route: '/manage-templates'});
                            } else if (!this.data_collection.includes('Manage Templates') && this.data_collection.includes('credit_system')) {
                                newimageItemsMid.push({id: 2, imageUrlMid: require('../assets/transaction.png'), altText: 'Credit System', route: '/credit-manager'});
                            }
                            newimageItemsMid.push({id: 3, imageUrlMid: require('../assets/faq_icon.png'), altText: 'FAQ', route: '/faq'});
                            if ( this.data_collection.includes('Reports')) {
                                newimageItemsMid.push({id: 4, imageUrlMid: require('../assets/report.png'), altText: 'Reports', route: '/reporting'});
                            }
                            //Put array data here
                            this.imageItemsTop = newItemsImageTop;
                            this.imageItemsManageDevice = newimageItemsManageDevice;
                            this.imageItemsMid = newimageItemsMid;
                            console.log("From LocalStorage data: ");
                            console.log(this.data_collection);
                        }
                        else{
                            //Error out here
                            console.log("No restrictions found");
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                    this.loading = false
                    this.tryagainWarning = true
                    
                });
            }


            var newItemsImageTop = [];
            var newimageItemsManageDevice = [];
            var newimageItemsMid = [];
            if ( this.data_collection ){
                //Parse the diff arrays here
                console.log(this.data_collection);
                 //Add accordingly
                if ( this.data_collection.includes('Register Device') ) {
                    newItemsImageTop.push({id: 1, imageUrlTop: require('../assets/register_device_icon.png'), altText: 'Register Device', route: '/register-devices'});
                }
                if ( this.data_collection.includes('Manage Devices')) {
                    newimageItemsManageDevice.push({id: 2, imageUrlManageDevice: require('../assets/manage_devices_icon.png'), altText: 'Manage Devices', route: '/device-list'});
                }
                if ( this.data_collection.includes('Manage Accounts')) {
                    newimageItemsMid.push({id: 1, imageUrlMid: require('../assets/manage_accounts.png'), altText: 'Manage Accounts / PoS', route: '/manage-user-admin'});
                }
                if ( this.data_collection.includes('Manage Templates') && this.data_collection.includes('credit_system')) {
                    newimageItemsMid.push({id: 2, imageUrlMid: require('../assets/settings.png'), altText: 'Settings', route: '/settings'});
                } else if (this.data_collection.includes('Manage Templates') && !this.data_collection.includes('credit_system')) {
                    newimageItemsMid.push({id: 2, imageUrlMid: require('../assets/template_icon.png'), altText: 'Manage Notification Templates', route: '/manage-templates'});
                } else if (!this.data_collection.includes('Manage Templates') && this.data_collection.includes('credit_system')) {
                    newimageItemsMid.push({id: 2, imageUrlMid: require('../assets/transaction.png'), altText: 'Credit System', route: '/credit-manager'});
                }
                newimageItemsMid.push({id: 3, imageUrlMid: require('../assets/faq_icon.png'), altText: 'FAQ', route: '/faq'});
                if ( this.data_collection.includes('Reports')) {
                    newimageItemsMid.push({id: 4, imageUrlMid: require('../assets/report.png'), altText: 'Reports', route: '/reporting'});
                }
                //Put array data here
                this.imageItemsTop = newItemsImageTop;
                this.imageItemsManageDevice = newimageItemsManageDevice;
                this.imageItemsMid = newimageItemsMid;
                console.log("From LocalStorage data: ");
                console.log(this.data_collection);
            }
            else{
                //Error out here
                console.log("No restrictions found");
            }
            
        }
    },
    async mounted() {
        const response = await fetch("/sidenav.json");
        const data = await response.json();
        await this.onlyLoadAccepted().then(() => {
        if (localStorage.token == undefined){
            this.goToPage('/')
        }
            this.data = data;
            this.langchange("ENG");
        });
    },
}
</script>

<style scoped>
    .sidebar {
        height:100dvh;
        width: 80px;
        background-color: #ffd600;
    }

    span{
        font-family: 'Century Gothic Bold';
        font-size: 11px;
        text-align: center;
    }

    .logo {
        width: 35px;
        height: 35px;
        margin-top: 15px;
        margin-bottom: 15px;
        cursor: pointer;
    }

    .image-container {
        width: 100%;
        height: 60px;
        margin-bottom: 5px;
        flex-direction: column;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
    .image-container:hover{
        background-color: rgba(255, 255, 255, 0.5);
    }

    .icon-image {
        width: 20px; /* Adjust the width and height based on your image dimensions */
        height: 20px;
        margin-bottom: 2px;
    }

    .mandev_icon{
        margin-right: 6px;
    }

    .image-label {
        font-size: 10px;
        width: 75px;
    }

    ul {
        list-style-type: none;
        padding: 0;
    }
    .mainmenu-container{
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }
    .misc-container{
        display: flex;
        height: 38%;
        flex-direction: column;
        justify-content: flex-end;
    }

    @media(max-width: 430px){
        .mainmenu-container{
            margin-top: 8px;
        }
        .misc-container{
            height: 50%;
        }
    }

    @media(max-width: 376px){
        .mainmenu-container{
            margin-top: 3px;
        }
        .misc-container{
            height: 36%;
        }
        .image-container{
            margin-bottom: 2px;
        }
        .sidebar{
            width: 60px
        }
        .icon-image{
            width: 20px;
            height: 20px;
        }
        .image-label{
            font-size: 8px;
            width: 55px;
        }
    }
</style>